import {Component} from '@angular/core';
import {environment} from "../../../../../../../../environments/environment";
import {ROUTE} from "../../../../../common/constant/routing.constant";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'tal-navigate-showcase',
    standalone: true,
    imports: [],
    templateUrl: './navigate-showcase.component.html',
    styleUrl: './navigate-showcase.component.scss'
})
export class NavigateShowcaseComponent {

    constructor(private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.setTalentId();
    }

    private setTalentId(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            const candidateId = params.get(ROUTE.CANDIDATE_ID);
            if (candidateId) {
                this.gotoShowcaseApp(candidateId);
            }
        });
    }

    private gotoShowcaseApp(talentId: string): void {
        window.open(environment.showcaseBaseAppUrl + ROUTE.SLASH + ROUTE.CANDIDATE + ROUTE.SLASH + talentId, '_self');
    }
}

<div class="w-full job-apply-container h-screen py-12 overflow-y-auto">
    <div class="m-auto job-apply-content">
        <div class="header-card overflow-hidden box-shadow mb-8">
            @if (isLoading) {
                <lib-card-skeleton [layout]="SKELETON_LAYOUT_TYPE.VERTICAL">
                </lib-card-skeleton>
            } @else {
                <lib-flat-card [imageUrl]="jobPostOutDto?.organizationMinOutDto?.logoUrl
                                   || 'assets/images/work-experience-default-logo.png'"
                               [title]="jobPostOutDto?.jobTitle"
                               [isBorder]="false"
                               [linkText]="jobPostOutDto?.organizationMinOutDto?.name"
                               [linkUrl]="getCareerSiteUrl()">
                </lib-flat-card>
                <lib-stepper class="pointer-events-none"
                             [steps]="libStepperModels"
                             (onStepChange)="updatedActiveLibStepperModel($event)">
                </lib-stepper>
            }
        </div>
        <ng-container content>
            <div class="overflow-y-auto">
                @if (isLoading) {
                    <lib-card-skeleton [layout]="SKELETON_LAYOUT_TYPE.VERTICAL">
                    </lib-card-skeleton>
                } @else {
                    @switch (activeLibStepperModel?.id) {
                        @case (JOB_APPLY_STEP_NAME.ACCOUNT_INFORMATION) {
                            <tal-account-info [jobPostOutDto]="jobPostOutDto"></tal-account-info>
                        }
                    }
                }
            </div>
        </ng-container>
    </div>
</div>

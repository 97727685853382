import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatButton, MatIconButton} from "@angular/material/button";
import {NgClass, NgTemplateOutlet} from "@angular/common";
import {MatTooltip, TooltipPosition} from "@angular/material/tooltip";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
    selector: 'lib-button',
    standalone: true,
    imports: [
        MatButton,
        NgClass,
        NgTemplateOutlet,
        MatTooltip,
        MatIcon,
        MatProgressSpinner,
        MatIconButton
    ],
    templateUrl: './lib-button.component.html',
    styleUrls: ['./lib-button.component.scss']
})
export class LibButtonComponent implements OnInit {

    @Input() disabled: boolean = false;
    @Input() iconClass: string;
    @Input() iconPosition: BUTTON_ICON_POSITION = BUTTON_ICON_POSITION.BEFORE;
    @Input() libType: BUTTON_TYPE = BUTTON_TYPE.BASIC;
    @Input() libSubType: BUTTON_SUB_TYPE = BUTTON_SUB_TYPE.PRIMARY;
    @Input() tooltipText: string;
    @Input() spinner = false;
    @Input() autoStopSpinner = true;
    @Input() svgUrl: string;
    @Input() showBorder = true;
    @Input() disable: boolean;
    @Input() matTooltipPosition: TooltipPosition = TOOL_TIP_TYPE.ABOVE;

    @Output() buttonClick = new EventEmitter();

    BUTTON_TYPE = BUTTON_TYPE;
    BUTTON_SUB_TYPE = BUTTON_SUB_TYPE;
    BUTTON_ICON_POSITION = BUTTON_ICON_POSITION;

    constructor() {
    }

    ngOnInit(): void {
    }

    onClick(mouseEvent: MouseEvent): void {
        if (this.autoStopSpinner) {
            this.changeButtonState(true);
            setTimeout(() => this.changeButtonState(false), 1500);
        }
        this.buttonClick.emit(mouseEvent);
    }

    private changeButtonState(loading: boolean): void {
        this.spinner = loading;
    }
}

export enum BUTTON_TYPE {
    BASIC = 'basic',
    GRADIENT = 'gradient',
    LARGE = 'large',
    ICON = 'icon',
    SMALL_ICON = 'smallIcon',
    FAB = 'fab',
}

export enum BUTTON_SUB_TYPE {
    DEFAULT = 'default',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    DANGER = 'danger',
    DANGER_SECONDARY = 'danger-secondary',
    WARNING = 'warning',
    SUCCESS = 'success',
    GRADIENT = 'gradient'
}

export enum BUTTON_ICON_POSITION {
    BEFORE = 'before',
    AFTER = 'after'
}

export enum TOOL_TIP_TYPE {
    BELOW = 'below',
    ABOVE = 'above',
    LEFT = 'left',
    RIGHT = 'right'
}

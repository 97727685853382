import {Routes} from '@angular/router';
import {ROUTE} from "./standout/home-layout/common/constant/routing.constant";
import {AuthGuard} from "./standout/authentication/common/service/auth/auth.gaurd";
import {PublicJobApplyComponent} from "./standout/authentication/public-job-apply/public-job-apply.component";
import {
    NavigateShowcaseComponent
} from "./standout/home-layout/portfolio/resume/common/component/navigate-showcase/navigate-showcase.component";

export const routes: Routes = [
    {
        path: '',
        title: 'Standout - Talenlio',
        data: {
            name: ROUTE.DASHBOARD
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./standout/home-layout/home-layout.route')
            .then(route => route.HOME_LAYOUT_ROUTES)
    },
    {
        path: ROUTE.AUTH,
        title: 'Standout',
        data: {
            name: ROUTE.AUTH
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./standout/authentication/authentication.route').then(route => route.AUTHENTICATION_ROUTES),
    },
    {
        path: ROUTE.SUPPORT,
        data: {
            name: ROUTE.AUTH
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./standout/authentication/support-layout/support-layout.route').then(route => route.SUPPORT_LAYOUT_ROUTES),
    },
    {
        path: ROUTE.FORM,
        title: 'Forms',
        loadChildren: () => import('./standout/authentication/form/talent-group-form.route').then(route => route.BATCH_FORM_ROUTES),
        data: {
            name: ROUTE.PUBLIC
        }
    },
    {
        path: ROUTE.PUBLIC + ROUTE.SLASH + ROUTE.JOBS + ROUTE.SLASH + ROUTE.COLON + ROUTE.JOB_ID + ROUTE.SLASH + ROUTE.APPLY,
        component: PublicJobApplyComponent
    },
    {
        path: ROUTE.SHOWCASE + ROUTE.SLASH + ROUTE.CANDIDATE + ROUTE.SLASH + ROUTE.COLON + ROUTE.CANDIDATE_ID,
        title: 'Checkout my Resume',
        component: NavigateShowcaseComponent,
        data: {
            name: 'public'
        }
    },
    {
        path: ROUTE.WILDCARD,
        redirectTo: '',
    }
];

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientWrapperService} from "../../../common/service/http-client-wrapper.service";
import {SearchInDto, SearchOutDto} from "../../../common/model/search.model";
import {LocationOutDto} from "../../../common/model/location/location.model";
import {CURRENCY_CODE} from "../../../common/util/currency.util";
import {FileStoreMinOutDto} from "../../../common/component/upload/common/service/document.service";
import {DegreeOutDto} from "../../../common/service/common-degree.service";
import {ApplicantMinOutDto} from "./applicant.service";
import {APPLICANT_STATUS_TYPE} from "./talent-has-job-application.service";
import {JOB_POST_STATUS, MEMBER_TYPE, PLACEMENT_DRIVE_STATUS, ROUND_TYPE} from "../util/job-post-status.enum";
import {JobPostOutDto} from "./job-post.service";

@Injectable({
    providedIn: 'root'
})
export class TalentHasJobService {

    private talentUrl = '/talents';
    private jobPostUrl = '/jobPosts';
    private driveJobsUrl = '/driveJobs';
    private searchUrl = '/search';

    constructor(private httpClientWrapperService: HttpClientWrapperService) {
    }

    search(talentId: string, candidateHasJobPostSearchInDto: TalentHasJobPostSearchInDto):
        Observable<SearchOutDto<TalentHasJobPostMinOutDto>> {
        return this.httpClientWrapperService.post(`${this.getSearchUrl(talentId)}`, candidateHasJobPostSearchInDto);
    }

    searchPlacementDriveJobs(talentId: string, candidateHasJobPostSearchInDto: TalentHasJobPostSearchInDto):
        Observable<SearchOutDto<TalentHasJobPostMinOutDto>> {
        return this.httpClientWrapperService.post(`${this.getDriveSearchUrl(talentId)}`, candidateHasJobPostSearchInDto);
    }

    private getSearchUrl(talentId: string): string {
        return `${this.talentUrl}/${talentId}${this.jobPostUrl}${this.searchUrl}`;
    }

    private getDriveSearchUrl(talentId: string): string {
        return `${this.talentUrl}/${talentId}${this.driveJobsUrl}${this.searchUrl}`;
    }
}

export class TalentHasJobPostMinOutDto {
    jobPostOutDto: JobPostOutDto;
    appliedAt: string;
    candidateHasJobPostId: string;
    employerUrlHandle: string;
    resumeIds: string[];
    applicantId: string;
    driveMinOutDto: DriveMinOutDto;
    applicantMinOutDto: ApplicantMinOutDto;
    currentApplicantStatusOutDto: ApplicantStatusOutDto;
}

export class ApplicantStatusCommonDto {
    displayName: string;
    color?: string;
    applicantStatusType?: APPLICANT_STATUS_TYPE;
}

export class ApplicantStatusOutDto extends ApplicantStatusCommonDto {
    id: string;
}

export class DriveMinOutDto {
    placementDriveStatus: PLACEMENT_DRIVE_STATUS;
    name: string;
    fromDate: string;
    toDate: string;
    startTime: string;
    endTime: string;
    id: string;
    createdAt: string;
    lastModifiedAt: string;
}

export class HiringRoundMinOutDto {
    id: string;
    name: string;
    roundType: ROUND_TYPE;
    metadata: string;
    ctaDisplayText: string;
}

export class JobPostHasHiringRoundMinOutDto {
    id: string;
    rowIndex: number;
    hiringRoundOutDto: HiringRoundOutDto;
}

export class TalentHasJobPostSearchInDto extends SearchInDto {
    jobPostIds?: string[];
    employerIds?: string[];
    myApplication?: boolean = false;
    relevantJob?: boolean;
    applicantStatuses?: APPLICANT_STATUS_TYPE[];
    applicantStatusIds?: string[];
    locations?: string[];
    jobLevels?: string[];
    departments?: string[];
}

export class AssessmentCardOutDto {
    hiringRoundType: ROUND_TYPE;
    metadata: string;
    ctaDisplayText: string;
    companyName: string;
}

export class JobPostCommonDto {
    status?: JOB_POST_STATUS;
    applicationLink?: string;
    urlHandle?: string;
    responsibilities?: string;
    description?: string;
    requirements?: string;
    minSalary?: string;
    maxSalary?: string;
    currency?: CURRENCY_CODE;
    jobFeatured?: boolean;
    workType?: string;
    expectedResumeScore?: number;
    autoRejectDays?: string;
    publicJob?: boolean;
}

export class JobPostHasDegreeOutDto {
    id: string;
    degreeOutDto: DegreeOutDto;
}

export class HiringRound {
    name: string;
    roundType: ROUND_TYPE;
    candidatePreparationMaterial: string;
    employerPreparationMaterial: string;
    metadata: string;
    ctaDisplayText: string;
}

export class HiringRoundOutDto extends HiringRound {
    id: string;
    scorecardOutDtos: ScorecardOutDto[];
}

export class ScorecardHasAttribute {
    attributeName: string;
}

export class ScorecardHasAttributeOutDto extends ScorecardHasAttribute {
    id: string;
}

export class Scorecard {
    name: string;
}

export class ScorecardOutDto extends Scorecard {
    id: string;
    scorecardHasAttributeOutDtos: ScorecardHasAttributeOutDto[];
}

export class OrganizationCommonDto {
    name?: string;
    websiteLink?: string;
    urlHandle?: string;
    address?: string;
    domain?: string;
}

export class EmployerHasMember {
    employerMemberType: MEMBER_TYPE;
}

export class OrganizationMinOutDto extends OrganizationCommonDto {
    id: string;
    organizationStatus: ORGANIZATION_STATUS;
    coverPictureUrl: string;
    faviconUrl: string;
    logoUrl:string
}

export class JobPostMinOutDto extends JobPostCommonDto {
    id: string;
    externalJob: boolean;
    locationOutDtos: LocationOutDto[];
    organizationOutDto: OrganizationOutDto;
    createdAt: string;
    lastModifiedAt: string;
}

export class OrganizationOutDto extends OrganizationCommonDto {
    id: string;
    brandingFileStore: FileStoreMinOutDto;
    // TODO : add later CareerSiteOutDto
    careerSiteOutDto: any;
    lastModifiedAt: string;
    createdAt: string;
}

export enum ORGANIZATION_STATUS {
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}
